<template>
  <h1>글쓰기 기본 화면</h1>
  <div class="board-write col-xl-8 col-lg-8 mx-auto">
    <text-editor :propsData="setData()" @emitData="uploadData"></text-editor>
  </div>

  <h4>Emit Data - Temp</h4>
  <p>{{ upload }}</p>
</template>

<style scoped>
  /* .board-write{} */
</style>

<script>
import TextEditor from '@/components/TextEditor.vue'
export default {
  name: '',
  components: { TextEditor },
  data() {
    return {
      contents: '<p>안녕하세요 <span style="color: #ff5900">나는</span> <span data-raw-html="span" style="color:blue">권영각</span>입니다.</p>',
      upload: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    setData() {
      // TODO: write, view, update 일 경우
      // '글쓰기'일 경우 비어있는 data
      // '보기', '수정'일 경우 DB에서 data를 받아와서오기
      // editor에 props data 전달
      return this.contents
    },
    uploadData(value) {
      // temp emit된 data 를 확인하기 위한
      // TODO: 새글, 수정된 contents를 서버에 저장하고 list화면으로 돌아가기
      this.upload = value
    }
  }
}
</script>
